import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const user = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  transition: 0.2s ease;

  &:hover {
    background: hsl(var(--color-backgroundLight-10));
  }

  td {
    padding: ${spacing(16)} 0;
  }
`;

export const userInfo = css`
  display: flex;
  align-items: center;
`;

export const userAvatar = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin: 0 ${spacing(16)} 0 0;
`;

export const userName = css`
  color: hsl(var(--color-text-1));
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-bold);
  font-size: ${spacing(16)};
  width: 100%;

  > span:first-of-type {
    margin: 0 ${spacing(10)} 0 0;
  }
`;

export const userNameClickable = css`
  ${userName};
  cursor: pointer;
`;

export const userEmail = css`
  color: hsl(var(--color-borderDark-5));
  font-size: var(--font-size-small);
`;

export const tag = css`
  margin-top: ${spacing(-6)};
  pointer-events: none;
  text-transform: capitalize;
  div {
    display: inline-flex;
    font-weight: var(--font-weight-regular);
    margin: 0 ${spacing(5)} 0 0;
  }
`;

export const deletedAtTag = css`
  color: hsl(var(--color-white-11));
  div {
    background: hsl(var(--color-borderBlack-2));
  }
`;

export const userRegistered = css`
  color: hsl(var(--color-text-1));
  display: block;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-small);
  white-space: nowrap;
`;

export const userIp = css`
  color: hsl(var(--color-borderDark-5));
  font-size: var(--font-size-small);
  white-space: nowrap;
`;

export const userRelatedTo = css`
  color: hsl(var(--color-borderDark-5));
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  span {
    display: block;
    margin: 0 ${spacing(10)} 0 0;
  }
`;

export const userActions = css`
  display: block;
  min-width: ${spacing(32)};
  text-align: right;

  span {
    display: inline-block;
  }

  button {
    &:hover {
      circle {
        stroke: hsl(var(--color-textLight-7));
      }

      path {
        fill: hsl(var(--color-textLight-7));
      }
    }
  }
`;

export const userActionsDropdown = css`
  border: 1px solid hsl(var(--color-borderUltraLight-9));
  border-radius: var(--border-radius-full);
  padding: ${spacing(9)} ${spacing(16)};
  transition: background-color 0.3s ease, border 0.3s ease;

  &:hover,
  &:active {
    border: 1px solid hsl(var(--color-text-1));
  }
`;

export const userCreatedRequest = css`
  display: flex;
`;
